import type { JWTPayload } from 'jose';
import { extractToken, getCookie } from './modules/cookie';
import { importWithBrand } from './utils/importWithBrand';
import type { Slots as LoginWallOverlaySlots } from './modules/login-wall/LoginWallOverlay.preprocess';
import type { Slots as IdentityWallOverlaySlots } from './modules/identity-wall/IdentityWallOverlay.preprocess';
import type { AnalyticsOptions } from './utils/analytics';
import { Events, generateEventName, getEmitter } from './utils/emitter';
import { registerPageVisibilityListener } from './utils/pageVisibilityListener';

/**
 * A quick note on the types used in this file:
 */

/**
 * The id token is a JWT token that contains information about the user.
 */
export interface IdToken extends JWTPayload {
  isInFirstTimeLoginBrand: boolean;
  'https://mediahuis.com/principal-type': 'USER';
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  gender: {
    type: 'Male' | 'Female' | 'Other';
  };
  updated_at: string;
  email: string;
  email_verified: boolean;
}

/**
 * The access token is a JWT token that contains access information about the user.
 */
export interface AccessToken extends JWTPayload {
  'https://mediahuis.com/principal-type': 'USER';
  'https://mediahuis.com/subscribed-accesses'?: Array<string>;
  'https://mediahuis.com/identity-levels'?: Array<string>;
  iss: string;
  sub: string;
  aud: Array<string>;
  iat: number;
  exp: number;
  azp: string;
  scope: string;
}

export interface FullIdentity {
  email: string;
  emailVerified: boolean;
  namesPerson: {
    nickName: string | null;
    firstName: string | null;
    lastName: string | null;
  };
  demographicsPerson: {
    genderCode: 'u' | 'm' | 'f' | 'o' | null;
    genderCustom: string | null;
    dateOfBirth: string | null;
  };
  contactpointsPhone: {
    phone: string | null;
  };
  address: {
    street: string | null;
    houseNumber: string | null;
    houseNumberExtension: string | null;
    city: string | null;
    postalCode: string | null;
    countryCode: string | null;
    qualityLabel: string | null;
  };
  identityLevels: Array<string> | null;
}

interface Config {
  env: string;
  brand: string;
  version: string;
  prefix?: string;
}

/**
 * @private
 * @param clientId Auth0 client id
 * @returns {Config}
 */
export function getConfig(clientId: string): Config {
  const cookieValue = getCookie(`auth0_${clientId}_config`);
  if (!cookieValue) {
    throw new Error(`No config found for client id: ${clientId}`);
  }
  const params = new URLSearchParams(cookieValue);

  return (Array.from(params.entries()) as Array<[keyof Config, Config[keyof Config]]>).reduce((acc, [key, value]) => {
    acc[key] = value;

    return acc;
  }, {} as Config);
}

/**
 * Get the access token of a logged in user. Useful when you need to send this token to a backend service from the client.
 *
 * @param clientId Auth0 client id
 * @returns Access token or null
 */
export function getAccessToken(clientId: string) {
  return getCookie(`auth0_${clientId}_acc_token`);
}

/**
 * Returns all subscribed access claims.
 *
 * @param clientId Auth0 client id
 * @returns The list of claims
 */
export async function getUserSubscriptions(clientId: string) {
  const decodedAccessToken = await getDecodedAccessToken(clientId);

  return decodedAccessToken?.['https://mediahuis.com/subscribed-accesses'] ?? [];
}

/**
 * Returns the access token fully parsed and decoded of a loggedin user.
 *
 * @param clientId Auth0 client id
 * @returns The parsed access token or null
 */
export async function getDecodedAccessToken(clientId: string): Promise<AccessToken> {
  return extractToken<AccessToken>(`auth0_${clientId}_acc_token`);
}

/**
 * Retrieve the raw id token of a logged in user. If you're looking to extract this infomration you can simply use the `getUserInfo` function instead.
 *
 * @param clientId Auth0 client id
 * @returns Raw JWT id token or null
 */
export function getIdToken(clientId: string) {
  return getCookie(`auth0_${clientId}_id_token`);
}

/**
 * Returns the id token fully parsed and decoded of a loggedin user.
 *
 * @param clientId Auth0 client id
 * @returns The parsed id token or null
 */
export async function getDecodedIdToken(clientId: string): Promise<IdToken> {
  return extractToken<IdToken>(`auth0_${clientId}_id_token`);
}

/**
 * Returns the id token fully parsed and decoded of a loggedin user.
 *
 * @param clientId Auth0 client id
 * @returns The parsed id token or null
 */
export async function getUserInfo(clientId: string): Promise<IdToken> {
  return getDecodedIdToken(clientId);
}

/**
 * Returns the id token fully parsed and decoded of a loggedin user.
 *
 * @param clientId Auth0 client id
 * @returns The parsed id token or null
 */
export async function getFullUserProfile(clientId: string): Promise<FullIdentity | null> {
  if (!isAuthenticated(clientId)) {
    return null;
  }

  const accessToken = await getDecodedAccessToken(clientId);

  const config = getConfig(clientId);
  let domain = `https://identity-management.mediahuis.com`;
  if (config.env === 'test') {
    domain = `https://identity-management-tst.mediahuis.com`;
  } else if (config.env === 'preview' || config.env === 'staging') {
    domain = `https://identity-management-uat.mediahuis.com`;
  } else if (config.env === 'dev') {
    domain = `https://identity-management-dev.mediahuis.com`;
  }
  const url = new URL(`/api/identities/${accessToken.sub}`, domain);
  const response = await fetch(url, {
    headers: {
      authorization: `Bearer ${getAccessToken(clientId)}`,
    },
  });

  if (!response.ok) {
    return null;
  }

  return response.json();
}

/**
 * Refreshes the access token of a logged in user.
 *
 * @param clientId Auth0 client id
 * @returns True if the token was succesfully refreshed, false otherwise
 */
export async function refreshAccessToken(clientId: string): Promise<boolean> {
  const hasRefreshToken = !!getCookie(`auth0_${clientId}_ref_token`);
  if (!hasRefreshToken) {
    return false;
  }

  const config = getConfig(clientId);

  try {
    const response = await fetch(`${config.prefix}/auth/refresh`, {
      method: 'POST',
    });
    if (!response.ok) {
      return false;
    }

    const { ok } = await response.json();

    return ok;
  } catch (_err) {
    return false;
  }
}

/**
 * Attach a listener to an event.
 *
 * @param clientId Auth0 client id
 * @param event the name of the event to listen to
 * @param callback your callback function
 */
export function on(clientId: string, event: Events, callback: () => void): void {
  // if someone is listening we can capture the clientId and start listening for expired tokens
  let isUserAuthenticated = isAuthenticated(clientId);
  let currentAccessToken = getAccessToken(clientId);

  registerPageVisibilityListener(async (isVisible) => {
    if (isVisible) {
      if (isUserAuthenticated) {
        // still authenticated but access token has changed
        if (isAuthenticated(clientId) && currentAccessToken !== getAccessToken(clientId)) {
          getEmitter().emit(generateEventName('auth:session_changed', clientId));
          currentAccessToken = getAccessToken(clientId);
          return;
        }

        const accessToken = await getDecodedAccessToken(clientId);
        // if expired
        if (!isAuthenticated(clientId) || accessToken?.exp - Date.now() <= 0) {
          const isRefreshed = await refreshAccessToken(clientId);
          isUserAuthenticated = isAuthenticated(clientId);
          if (isRefreshed) {
            currentAccessToken = getAccessToken(clientId);
            getEmitter().emit(generateEventName('auth:session_changed', clientId));
          } else {
            getEmitter().emit(generateEventName('auth:session_expired', clientId));
          }
        }
      } else {
        if (isAuthenticated(clientId)) {
          getEmitter().emit(generateEventName('auth:session_changed', clientId));
          currentAccessToken = getAccessToken(clientId);
          isUserAuthenticated = true;
        }
      }
    }
  });

  getEmitter().on(generateEventName(event, clientId), callback);
}

/**
 * Detach a listener to an event.
 *
 * @param clientId Auth0 client id
 * @param event the name of the event to listen to
 * @param callback your callback function
 */
export function off(clientId: string, event: Events, callback: () => void): void {
  getEmitter().off(generateEventName(event, clientId), callback);
}

/**
 * Is the user authenticated?
 *
 * @param clientId Auth0 client id
 * @returns True if the user is authenticated, false otherwise
 */
export function isAuthenticated(clientId: string): boolean {
  return !!getAccessToken(clientId) && !!getIdToken(clientId);
}

/**
 * Is the email verified?
 *
 * @param clientId Auth0 client id
 * @returns True if the email is verified, false otherwise
 */
export async function isEmailVerified(clientId: string): Promise<boolean> {
  const userInfo = await getUserInfo(clientId);

  if (!userInfo) {
    return false;
  }

  return userInfo.email_verified;
}

export async function hasIdentityLevel(clientId: string, identityLevel: string): Promise<boolean> {
  const accessToken = await getDecodedAccessToken(clientId);

  return accessToken['https://mediahuis.com/identity-levels']?.includes(identityLevel) ?? false;
}

export async function sendEmailVerification(clientId: string): Promise<boolean> {
  const userInfo = await getDecodedIdToken(clientId);
  if (userInfo.email_verified) {
    return false;
  }

  const config = getConfig(clientId);
  const verifyEmailUrl = new URL(`${config.prefix}/auth/verify-email`, window.location.origin);
  verifyEmailUrl.searchParams.append('accountId', userInfo.sub);

  try {
    const resp = await fetch(verifyEmailUrl, {
      headers: {
        authorization: 'Bearer ' + getAccessToken(clientId),
      },
    });

    return resp.ok;
  } catch (err) {
    return false;
  }
}

/**
 * When not loggedin show login wall.
 * showLoginWall let you put our shared component based on the Mediahuis design system in your page.
 *
 * @param options The list of options to pass to the popup
 * @param options.type The type of email confirmation to show
 * @param options.loginIdentityLevel The identity level to login
 * @param options.registerIdentityLevel The identity level to register
 * @param options.el The DOM element to render the email confirmation in
 * @param options.slots.title Custom title for login wall
 * @param options.slots.content Custom content for login wall
 * @param options.slots.callToAction Custom call to action for login wall
 * @param options.onTrackEvent Function to track events
 * @returns
 */
export async function showLoginWall({
  type,
  clientId,
  loginIdentityLevel,
  registerIdentityLevel,
  el,
  slots = {},
  options,
  onTrackEvent,
}: {
  type: 'paywall' | 'inline' | 'overlay';
  clientId: string;
  loginIdentityLevel: string;
  registerIdentityLevel: string;
  el: HTMLElement;
  slots?: Partial<LoginWallOverlaySlots>;
  options: Partial<AnalyticsOptions>;
  onTrackEvent: (event: any) => void;
}): Promise<void> {
  if (!['paywall', 'inline', 'overlay'].includes(type)) {
    throw new Error(`Unsupported type: ${type}, only paywall, inline or overlay is currently implemented.`);
  }

  if (!el) {
    throw new Error(`No element provided to render the identity wall in.`);
  }

  if (isAuthenticated(clientId)) {
    throw new Error(`User is authenticated.`);
  }

  const config = getConfig(clientId);

  if (type === 'overlay') {
    const { appendLoginWall } = await importWithBrand('./modules/login-wall/LoginWallOverlay', config.brand);

    return appendLoginWall(el, {
      requiredLoginIdentityLevel: loginIdentityLevel,
      requiredRegisterIdentityLevel: registerIdentityLevel,
      brand: config.brand,
      slots,
      onTrackEvent,
      options,
    });
  } else {
    const { appendLoginWall } = await importWithBrand('./modules/login-wall/LoginWall', config.brand);

    return appendLoginWall(el, {
      requiredLoginIdentityLevel: loginIdentityLevel,
      requiredRegisterIdentityLevel: registerIdentityLevel,
      brand: config.brand,
      clientId,
      slots,
      onTrackEvent,
      options,
    });
  }
}

/**
 * Some brands require a specific identity level before reading any metered or plus articles.
 * showIdentityWall let you put our shared component based on the Mediahuis design system in your page.
 *
 * @param options The list of options to pass to the popup
 * @param options.clientId Auth0 client id
 * @param options.type The type of email confirmation to show
 * @param options.requiredIdentityLevel The identity level to progress
 * @param options.el The DOM element to render the email confirmation in
 * @param options.slots.title Custom title for login wall
 * @param options.slots.content Custom content for login wall
 * @param options.slots.callToAction Custom call to action for login wall
 * @param options.onTrackEvent Function to track events
 * @param options.options["ext-internal"] Internal tracking parameter
 * @returns
 */
export async function showIdentityWall({
  type,
  clientId,
  identityLevel,
  el,
  slots = {},
  onTrackEvent,
  options,
}: {
  type: 'paywall' | 'overlay' | 'inline';
  clientId: string;
  identityLevel: string;
  el: HTMLElement;
  slots?: Partial<IdentityWallOverlaySlots>;
  onTrackEvent: (event: Event) => void;
  options: Partial<AnalyticsOptions>;
}): Promise<void> {
  // if (type !== 'paywall') {
  // 	throw new Error(`Unsupported type: ${type}, only paywall is currently implemented.`);
  // }

  if (!el) {
    throw new Error(`No element provided to render the identity wall in.`);
  }

  if (!isAuthenticated(clientId)) {
    throw new Error(`User is not authenticated.`);
  }

  const config = getConfig(clientId);

  if (type === 'overlay') {
    const { appendIdentityWall } = await importWithBrand('./modules/identity-wall/IdentityWallOverlay', config.brand);

    return appendIdentityWall(el, {
      requiredIdentityLevel: identityLevel,
      brand: config.brand,
      clientId,
      slots,
      onTrackEvent,
      options,
    });
  } else {
    const { appendIdentityWall } = await importWithBrand('./modules/identity-wall/IdentityWall', config.brand);

    return appendIdentityWall(el, {
      requiredIdentityLevel: identityLevel,
      brand: config.brand,
      clientId,
      slots,
      onTrackEvent,
      options,
    });
  }
}

/**
 * Some brands require a specific identity to be show  before reading any metered or plus articles.
 *
 * @param options The list of options to pass to the popup
 * @param options.clientId Auth0 client id
 * @param options.type The type of email confirmation to show
 * @param options.el The DOM element to render the email confirmation in
 * @param options.onTrackEvent Function to track events
 * @returns
 */
export async function showEmailConfirmation({
  type,
  clientId,
  el,
  onTrackEvent,
}: {
  type: 'paywall' | 'inline';
  clientId: string;
  el: HTMLElement;
  onTrackEvent: (event: Event) => void;
}) {
  if (type !== 'paywall' && type !== 'inline') {
    throw new Error(`Unsupported type: ${type}, only paywall or inline is currently implemented.`);
  }

  if (!isAuthenticated(clientId)) {
    throw new Error(`User is not authenticated.`);
  }

  if (!el) {
    throw new Error(`No element provided to render the email confirmation box in.`);
  }

  const userInfo = await getDecodedIdToken(clientId);

  if (userInfo.email_verified) {
    throw new Error(`User has already been verified.`);
  }

  const config = getConfig(clientId);

  const { appendEmailVerificationWall } = await importWithBrand('./modules/email-confirmation/EmailConfirmationWall', config.brand);
  return appendEmailVerificationWall(el, { email: userInfo.email, brand: config.brand, clientId, onTrackEvent });
}

/**
 * If you want to control when to show the silent login popup.
 *
 * Note that we're using the Mediahuis design system to create these popups.
 *
 * @param options The list of options to pass to the popup
 * @param options.clientId Auth0 client id
 * @param options.el The DOM element to render the silent login notification in
 * @returns
 */
export async function showSilentLoginNotification({ clientId, el }: { clientId: string; el: HTMLElement }): Promise<void> {
  const parsedUrl = new URL(window.location.href);
  parsedUrl.searchParams.delete('_sl');
  window.history.replaceState(history.state, undefined, parsedUrl.toString());
  const userInfo = await getUserInfo(clientId);

  if (!userInfo.email) {
    return;
  }

  if (!el) {
    throw new Error(`No element provided to render the silent login toast in.`);
  }

  const { appendNotificationToast } = await importWithBrand('./modules/silent-login/NotificationToast', 'mh');

  Object.assign(el.style, {
    position: 'fixed',
    zIndex: '9999',
    top: 0,
    right: 0,
  });

  const config = getConfig(clientId);
  await appendNotificationToast(el, { email: userInfo.email, brand: config.brand });

  return new Promise((resolve) => {
    requestAnimationFrame(() => {
      resolve();
    });
  });
}

/**
 * Options for specifying identity levels required for login and registration.
 *
 * @param loginIdentity The identity level required for login.
 * @param registerIdentity The identity level required for registration.
 */
type IdentityLevelOptions = {
  loginIdentity: string;
  registerIdentity: string;
};

/**
 * General options for constructing URLs and handling authentication flows.
 *
 * @param returnTo The URL to redirect to after login.
 * @param login The base login URL. Defaults to '/auth/login'.
 * @param connection The name of the social connection (eg. 'mh-google-social' for Google).
 * @param skipEmailVerification Whether to skip email verification. Defaults to false.
 */
type GeneralOptions = {
  returnTo?: string;
  login?: string;
  connection?: string;
  skipEmailVerification?: boolean;
};

/**
 * Type definition for analytics parameters.
 * These parameters are used for tracking and analytics purposes.
 *
 * @param articleId Article ID.
 * @param prsid PRS ID.
 * @param cid CID.
 * @param internal Internal tracking parameter.
 */
type TrackingOptions = {
  articleId?: string;
  prsid?: string;
  cid?: string;
  internal?: string;
};

/**
 * Constructs the login URL with the specified parameters.
 *
 * @param clientId - The Auth0 client id.
 * @param identityLevel The identity level options required for login and registration.
 * @param identityLevel.loginIdentity The identity level required for login.
 * @param identityLevel.registerIdentity The identity level required for registration.
 * @param options - General options for constructing the URL.
 * @param options.skipEmailVerification - Whether to skip email verification. Defaults to false.
 * @param options.connection - The name of the social connection (e.g., 'mh-google-social' for Google).
 * @param options.returnTo - The URL to redirect to after login. Defaults to the current window location.
 * @param tracking - Tracking options for analytics purposes.
 * @param tracking.articleId - Article ID for tracking.
 * @param tracking.prsid - PRS ID for tracking.
 * @param tracking.cid - CID for tracking.
 * @param tracking.internal - Internal tracking parameter.
 * @returns A promise that resolves to the constructed login URL as a string.
 */
export async function getLoginUrl({
  clientId,
  identityLevel,
  options,
  tracking,
}: {
  clientId: string;
  identityLevel: IdentityLevelOptions;
  options: GeneralOptions;
  tracking: TrackingOptions;
}): Promise<string> {
  return await buildUrl({
    identityLevel: identityLevel,
    options: options,
    tracking: tracking,
    clientId: clientId,
    authType: 'login',
  });
}

/**
 * Constructs the register URL with the specified parameters.
 *
 * @param clientId - The Auth0 client id.
 * @param identityLevel The identity level options required for login and registration.
 * @param identityLevel.loginIdentity The identity level required for login.
 * @param identityLevel.registerIdentity The identity level required for registration.
 * @param options - General options for constructing the URL.
 * @param options.skipEmailVerification - Whether to skip email verification. Defaults to false.
 * @param options.connection - The name of the social connection (e.g., 'mh-google-social' for Google).
 * @param options.returnTo - The URL to redirect to after registration. Defaults to the current window location.
 * @param tracking - Tracking options for analytics purposes.
 * @param tracking.articleId - Article ID for tracking.
 * @param tracking.prsid - PRS ID for tracking.
 * @param tracking.cid - CID for tracking.
 * @param tracking.internal - Internal tracking parameter.
 * @returns A promise that resolves to the constructed register URL as a string.
 */
export async function getRegisterUrl({
  clientId,
  identityLevel,
  options,
  tracking,
}: {
  clientId: string;
  identityLevel: IdentityLevelOptions;
  options: GeneralOptions;
  tracking: TrackingOptions;
}): Promise<string> {
  return await buildUrl({
    identityLevel: identityLevel,
    options: options,
    tracking: tracking,
    clientId: clientId,
    authType: 'register',
  });
}

/**
 * Constructs a URL with the specified parameters for either login or registration.
 *
 * @param searchParams - The URL search parameters to be appended.
 * @param options - General options for constructing the URL.
 * @param options.skipEmailVerification - Whether to skip email verification. Defaults to false.
 * @param options.connection - The name of the social connection (e.g., 'mh-google-social' for Google).
 * @param options.returnTo - The URL to redirect to after the operation. Defaults to the current window location.
 * @param tracking - Tracking options for analytics purposes.
 * @param tracking.articleId - Article ID for tracking.
 * @param tracking.prsid - PRS ID for tracking.
 * @param tracking.cid - CID for tracking.
 * @param tracking.internal - Internal tracking parameter.
 * @param clientId - The Auth0 client id.
 * @param authType - The type of authentication ('login' or 'register').
 * @returns A promise that resolves to the constructed URL as a string.
 */
async function buildUrl({
  identityLevel,
  options: { skipEmailVerification = false, connection, returnTo = window.location.href },
  tracking,
  clientId,
  authType,
}: {
  identityLevel: IdentityLevelOptions;
  options: GeneralOptions;
  tracking: TrackingOptions;
  clientId: string;
  authType: 'login' | 'register';
}): Promise<string> {
  const searchParams = new URLSearchParams();
  const config = getConfig(clientId);
  if (identityLevel.loginIdentity) {
    searchParams.append('login_identity_level', identityLevel.loginIdentity);
  }
  if (identityLevel.registerIdentity) {
    searchParams.append('register_identity_level', identityLevel.registerIdentity);
  }
  searchParams.append('skip_email_verification', skipEmailVerification ? '1' : '0');
  if (connection) {
    searchParams.append('connection', connection);
  }
  if (returnTo) {
    searchParams.append('returnTo', returnTo);
  }
  if (tracking.prsid) {
    searchParams.append('ext-prsid', tracking.prsid);
  }
  if (tracking.cid) {
    searchParams.append('ext-cid', tracking.cid);
  }
  if (tracking.internal) {
    searchParams.append('ext-internal', tracking.internal);
  }
  if (tracking.articleId) {
    searchParams.append('ext-articleId', tracking.articleId);
  }
  let prefix = config.prefix ? config.prefix : '/';
  if (!prefix.endsWith('/')) {
    prefix += '/';
  }
  const resultUrl = new URL(`${window.location.origin}${prefix}auth/${authType}`);
  resultUrl.search = searchParams.toString();
  return resultUrl.toString();
}
